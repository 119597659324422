import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom'; // react-router v4
import { ConnectedRouter } from 'connected-react-router';

import * as serviceWorker from './serviceWorker';

import { configureStore, history } from 'configureStore';
import Home from 'components/pages/Home';
import BalloonsPostcardsToys from 'components/pages/BalloonsPostcardsToys';
import Toys from 'components/pages/Toys';
import Postcards from 'components/pages/Postcards';
import Balloons from 'components/pages/Balloons';
import Bouquets from 'components/pages/Bouquets';
import Bouquet from 'components/pages/Bouquet';
import Contacts from 'components/pages/Contacts';
import Delivery from 'components/pages/Delivery';
import FAQ from 'components/pages/FAQ';
import NoMatch from 'components/pages/NoMatch';

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/balloons-postcards-toys' exact component={BalloonsPostcardsToys} />
                <Route path='/postcards' exact component={Postcards} />
                <Route path='/balloons' exact component={Balloons} />
                <Route path='/toys' exact component={Toys} />
                <Route path='/bouquets/:id' component={Bouquet} />
                <Route path='/bouquets' exact component={Bouquets} />
                <Route path='/contacts' exact component={Contacts} />
                <Route path='/delivery' exact component={Delivery} />
                <Route path='/faq' exact component={FAQ} />
                <Route component={NoMatch} />
            </Switch>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
